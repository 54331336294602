import React, { useState, useEffect, useRef } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import emailjs from '@emailjs/browser';
import fb from "../Firebase/firebase";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpArrow from '../Utilities/UpArrow';
import { Link } from 'react-router-dom';
import buy from "../Images/Homepage/buy.png"

const DB = fb.firestore()
const CF = DB.collection('Murine_Form');

const BuyNow = () => {


    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, []);

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Quantity: "",
        RetailerSelfUse: ""
    });

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Quantity, RetailerSelfUse, Products } = userData;
        if (Name && Email && Mobile && Quantity && RetailerSelfUse && Products) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                toast.error('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    toast.error("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    toast.error("Please enter 10 digit Mobile number.");
                    return
                }
            }

            try {

                CF.add({
                    Name: Name,
                    Email: Email,
                    Mobile: Mobile,
                    Quantity: Quantity,
                    RetailerSelfUse: RetailerSelfUse,
                    Products: Products
                }).then((docRef) => {
                    toast.success('Order Received Successfully');
                }).catch((error) => {
                    console.error("error:", error);
                });


                emailjs.sendForm('service_91sqknx', 'template_4j6wwya', form.current, '6pbmyufjSURA60XeS')
                    .then((result) => {
                        console.log(result.text);

                    }, (error) => {
                        console.log(error.text);
                    });

                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Quantity: "",
                    RetailerSelfUse: "",
                    Products: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                toast.error("Error adding document")
            }
        }
        else {
            toast.error('Please fill the data');
        }
    };

    return (
        <>
            < ToastContainer />
            <Header />

            <section className="buy" id='buy' style={{ paddingTop: "10%" }}>

                <h3 className="title">
                    Buy Now
                </h3>

                <div className="buy-body">
                    {/* <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1702878141/Callaxo/drug-capsule-pill-from-drug-prescription-drugstore-pharmacy-treatment-health-medicine_x21c4i.jpg" alt="" /> */}
                    <img src={buy} alt="" />
                    <form action="POST" ref={form}>
                        <div className='input'>
                            <input type="text" placeholder='Name'
                                name='Name'
                                value={userData.Name}
                                onChange={postUserData}
                                required />
                            <input type="email" placeholder='Email'
                                name='Email'
                                value={userData.Email}
                                onChange={postUserData}
                                required />
                        </div>
                        <div className='input'>
                            <input type="text" placeholder='Mobile'
                                name='Mobile'
                                value={userData.Mobile}
                                onChange={postUserData}
                                required />
                            <input type="number" min={1} placeholder='Quantity'
                                name='Quantity'
                                value={userData.Quantity}
                                onChange={postUserData}
                                required />
                        </div>
                        <input style={{ display: "none" }}
                            type="text"
                            name="productName"
                            value="Murine"
                        />
                        <select name='RetailerSelfUse'
                            value={userData.RetailerSelfUse}
                            onChange={postUserData}>
                            <option hidden selected="selected" disabled="disabled">Retailer / Self Use</option>
                            <option value="Retailer">Retailer</option>
                            <option value="Self Use">Self Use</option>
                        </select>
                        <select name="Products"
                            value={userData.Products}
                            onChange={postUserData}>
                            <option hidden selected="selected" disabled="disabled">Select Product</option>
                            <option value="Murine 300">Murine 300</option>
                            <option value="Murine XT">Murine XT</option>
                        </select>
                        <button onClick={SubmitData}>Submit</button>
                    </form>

                </div>
            </section>
            <Footer />
        </>
    )
}

export default BuyNow