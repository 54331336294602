import React, { useState, useEffect, useRef } from 'react'
import Header from '../Utilities/Header'
import "../Sass/Home.scss"
import m1 from "../Images/Products/MURINE-300 MOCK A.png"
import m2 from "../Images/Products/MURINE-XT MOCK A.png"

import p1 from "../Images/Products/MURINE-300 MOCK A.jpg"
import p2 from "../Images/Products/MURINE-XT MOCK A.jpg"

import buy from "../Images/Homepage/buy.png"
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fb from "../Firebase/firebase";
import Footer from '../Utilities/Footer'
import { Link } from 'react-router-dom'
const DB = fb.firestore()
const CF = DB.collection('Murine_Form');

const Home2 = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, []);

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Quantity: "",
        RetailerSelfUse: ""
    });

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Quantity, RetailerSelfUse, Products } = userData;
        if (Name && Email && Mobile && Quantity && RetailerSelfUse && Products) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                toast.error('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    toast.error("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    toast.error("Please enter 10 digit Mobile number.");
                    return
                }
            }

            try {

                CF.add({
                    Name: Name,
                    Email: Email,
                    Mobile: Mobile,
                    Quantity: Quantity,
                    RetailerSelfUse: RetailerSelfUse,
                    Products: Products
                }).then((docRef) => {
                    toast.success('Order Received Successfully');
                }).catch((error) => {
                    console.error("error:", error);
                });


                emailjs.sendForm('service_91sqknx', 'template_4j6wwya', form.current, '6pbmyufjSURA60XeS')
                    .then((result) => {
                        console.log(result.text);

                    }, (error) => {
                        console.log(error.text);
                    });

                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Quantity: "",
                    RetailerSelfUse: "",
                    Products: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                toast.error("Error adding document")
            }
        }
        else {
            toast.error('Please fill the data');
        }
    };

    return (
        <div>
            < ToastContainer />
            <Header />

            <div className="banner">
                <div className="banner-left">
                    <h1>Where innovation meets cure</h1>
                    <p>Murine is an advanced pharmaceutical formulation consisting of two therapeutic
                        products Murine-XT and Murine 300 which is designed to support overall well
                        being through scientifically formulated therapeutic support. Experience the
                        synergy of Coenzyme Q10, Omega-3 Fatty Acids, and Lycopene in Murine 300.
                        This powerful blend is crafted to boost cellular energy, support neurological,
                        oncological, gynaecological, cardiovascular health and provide potent
                        antioxidant protection. Murine XT is formulated to enhance heart function,
                        support cognitive health, and promote overall vitality. It is designed for those
                        seeking therapeutic treatment for a complete balanced lifestyle.</p>
                    <Link to={"/buy-now"}>buy now</Link>
                </div>

                <div className="banner-right">
                    <div className="circle"></div>
                    <img src={m1} alt="" />
                    <img src={m2} alt="" />
                </div>

            </div>

            <div className="products">
                <h3 className="title">
                    Our Products
                </h3>

                <div className="products-body">
                    <div to={"/products/murine-300"} className="products-card">
                        <div className="img">
                            <img src={p1} alt="Murine 300" />
                        </div>
                        <div className="headline">
                            <h4>Murine 300</h4>
                            <p>Murine-300 is a clinically advanced formulation enriched with CoQ10, Omega-3 fatty acids, and lycopene to support overall wellness. It promotes gynaecological health by improving ovarian function and reducing inflammation.</p>
                            <Link to={"/products/murine-300"}>Learn More</Link>
                        </div>
                    </div>
                    <div to={"/products/murine-xt"} className="products-card">
                        <div className="img">
                            <img src={p2} alt="Murine XT" />
                        </div>
                        <div className="headline">
                            <h4>Murine XT</h4>
                            <p>Murine-XT is a pharmaceutical therapeutic product designed to support overall health and vitality. Its active composition is designed to enhanced bioavailability, allowing for efficient absorption and utilization of essential nutrients.</p>
                            <Link to={"/products/murine-xt"}>Learn More</Link>
                        </div>
                    </div>
                </div>
            </div >

            {/* Order */}
            < div className="buy" id='buy' >

                <h3 className="title">
                    Buy Now
                </h3>

                <div className="buy-body">
                    {/* <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1702878141/Callaxo/drug-capsule-pill-from-drug-prescription-drugstore-pharmacy-treatment-health-medicine_x21c4i.jpg" alt="" /> */}
                    <img src={buy} alt="" />
                    <form action="POST" ref={form}>
                        <div className='input'>
                            <input type="text" placeholder='Name'
                                name='Name'
                                value={userData.Name}
                                onChange={postUserData}
                                required />
                            <input type="email" placeholder='Email'
                                name='Email'
                                value={userData.Email}
                                onChange={postUserData}
                                required />
                        </div>
                        <div className='input'>
                            <input type="text" placeholder='Mobile'
                                name='Mobile'
                                value={userData.Mobile}
                                onChange={postUserData}
                                required />
                            <input type="number" min={1} placeholder='Quantity'
                                name='Quantity'
                                value={userData.Quantity}
                                onChange={postUserData}
                                required />
                        </div>
                        <input style={{ display: "none" }}
                            type="text"
                            name="productName"
                            value="Murine"
                        />
                        <select name='RetailerSelfUse'
                            value={userData.RetailerSelfUse}
                            onChange={postUserData}>
                            <option hidden selected="selected" disabled="disabled">Retailer / Self Use</option>
                            <option value="Retailer">Retailer</option>
                            <option value="Self Use">Self Use</option>
                        </select>
                        <select name="Products"
                            value={userData.Products}
                            onChange={postUserData}>
                            <option hidden selected="selected" disabled="disabled">Select Product</option>
                            <option value="Murine 300">Murine 300</option>
                            <option value="Murine XT">Murine XT</option>
                        </select>
                        <button onClick={SubmitData}>Submit</button>
                    </form>

                </div>
            </div >
            {/* Order Ends */}

            < Footer />
        </div >
    )
}

export default Home2