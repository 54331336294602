import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Components/Sass/main.scss'
import Home2 from './Components/Pages/Home2';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy'
import Murine300 from './Components/Pages/Murine300';
import MurineXT from './Components/Pages/MurineXT';
import BuyNow from './Components/Pages/BuyNow';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home2 />} />
          <Route path="/products/murine-300" element={<Murine300 />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/products/murine-xt" element={<MurineXT />} />
          <Route path="/buy-now" element={<BuyNow />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
